import React, { useState, useEffect } from 'react';
import AboutImg from '../images/about_img.png';
import vision from '../images/visionary.png'
import mission from '../images/leadership.png'
import industry from '../images/indAcade.jpg'
import team from '../images/team.avif'
import about_img from '../images/about.jpg'
import Aos from 'aos'
import 'aos/dist/aos.css'


function Aboutpage() {

  document.title = 'About - WorknConnect Innovators'

    useEffect(() => {
        Aos.init({duration: 2000})
    },[])
    const [value, setValue] = useState('vision');

    return (
        <div>
            <div className="h-[40vh] my-10 w-full flex justify-center items-center relative">
                <img src={about_img} alt="" className="  absolute top-0 left-0 w-full h-full object-cover object-center" />
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 flex flex-col justify-center items-center">
                    <h1
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        className="lg:text-7xl text-5xl font-bold text-white z-10 relative after:absolute after:content-[''] after:block after:w-full after:h-1 after:bg-[#367ba0] after:bottom-[-10px] after:left-0"
                    >
                        About Us
                    </h1>
                </div>
            </div>

            <div className=' flex flex-col gap-y-10 '  >


                <div className='flex lg:flex-row flex-col w-full lg:gap-8 gap-4 lg:px-20 py-8 px-8'>
                    <div className=' lg:w-1/2 w-full bg-gray-100 shadow-lg p-10 border border-transparent hover:border-[#367ba0]'>
                        <h1 className='text-2xl text-[#367ba0] font-bold' >How it started</h1>
                        <p className='lg:text-3xl text-xl font-semibold my-10 ' >Our dream is to bridge the gap between <span className='text-[#367ba0]' >Industry & Academia</span></p>
                        <p className='text-justify text-sm text-gray-600' >Worknconnect Innovators is a pioneering company dedicated to bridging the gap between industry and academia through cutting-edge software development and innovative solutions. Our mission is to create a seamless link between educational institutions and the business world, fostering collaboration and driving forward the technological advancements that shape the future. We believe in the power of innovation to transform industries, enhance education, and create a thriving ecosystem where knowledge and practical application meet.</p>
                    </div>
                    <div className='lg:w-1/2 w-full flex flex-col gap-y-6' >
                        <img src={industry} alt='' className='h-60 w-full object-cover mx-auto bg-gray-100 shadow-lg border border-transparent hover:border-[#367ba0]' />
                        <div className='p-10 w-full bg-gray-100 shadow-lg border border-transparent hover:border-[#367ba0] flex justify-center  '>
                            <div className='grid lg:grid-cols-2 grid-cols-1 gap-y-8 gap-x-12 ' >
                                <div className='bg-slate-200 w-fit pl-8 pr-16 rounded-lg py-4' >
                                    <p className='text-2xl font-bold' >0.8+</p>
                                    <p className='text-gray-700'>Year Experience</p>
                                </div>
                                <div className='bg-slate-200 w-fit pl-8 pr-16 rounded-lg py-4' >
                                    <p className='text-2xl font-bold' >2+</p>
                                    <p className='text-gray-700'>Active Projects</p>
                                </div>
                                <div className='bg-slate-200 w-fit pl-8 pr-16 rounded-lg py-4' >
                                    <p className='text-2xl font-bold' >15+</p>
                                    <p className='text-gray-700'>Positive Reviews</p>
                                </div>
                                <div className='bg-slate-200 w-fit pl-8 pr-16 rounded-lg py-4' >
                                    <p className='text-2xl font-bold' >2+</p>
                                    <p className='text-gray-700'>Active Clients</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex lg:justify-around justify-center lg:px-20 px-8 py-8 bg-gray-100 items-center'>
                    <div className='w-1/2 lg:inline-block hidden'>
                        <img src={AboutImg} alt='about' />
                    </div>
                    <div className='lg:w-1/2 w-full'>
                        <div className='w-11/12'>
                            <h2 className='lg:text-4xl text-2xl font-medium'>Where Every software is optimized and responsive</h2>
                            <p className='my-4 text-justify text-gray-600'>
                                Software development is a field of building, optimizing, and maintaining applications that are both user-friendly and responsive, ensuring that they work seamlessly across different devices and platforms.
                            </p>
                            <div className='flex gap-x-6'>
                                <button
                                    onClick={() => setValue('vision')}
                                    className={`px-8 py-2 rounded-2xl text-lg font-normal border border-1 border-[#367ba0]  hover:bg-[#367ba0] hover:text-white hover:border-[#367ba0] ${value === 'vision' && 'bg-[#367ba0] text-white'}`}
                                >
                                    Vision
                                </button>
                                <button
                                    onClick={() => setValue('mission')}
                                    className={`px-8 py-2 rounded-2xl text-lg font-normal border border-1 border-[#367ba0]  hover:bg-[#367ba0] hover:text-white hover:border-[#367ba0] ${value === 'mission' && 'bg-[#367ba0] text-white'}`}
                                >
                                    Mission
                                </button>
                            </div>
                            <div className='mt-6 h-32'>
                                {value === 'vision' && <p className='flex gap-2'><img src={vision} alt='' className='h-10 my-auto' /> Transform student potential into industry-ready expertise, driving innovation and career success</p>}
                                {value === 'mission' && <p className='flex gap-2'><img src={mission} alt='' className='h-14 my-auto' /> Empower students with real-world project experience, fostering industry collaboration and delivering valuable outcomes for both students and businesses.</p>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='lg:flex lg:gap-8 lg:px-20  px-8 py-8 mb-10 '>
                    <div className='py-14'>
                        <h1 className='text-2xl font-bold text-[#367ba0]' >Who we are</h1>
                        <p className='lg:text-4xl text-2xl font-semibold my-6'>We are helping your company be successful online</p>
                        <p className='text-sm text-gray-600 text-justify lg:pr-20'>
                            At Worknconnect Innovators, we are passionate about empowering businesses to achieve their full potential in the digital world. Our team of experts is dedicated to providing innovative solutions that help companies succeed online. We understand the challenges of navigating the ever-evolving digital landscape, and we're here to guide you every step of the way. Whether you're looking to enhance your online presence, streamline operations, or drive growth through cutting-edge technology, we have the expertise and experience to make it happen. Our mission is to help your company thrive in the digital age, ensuring you stay ahead of the competition and reach new heights of success.
                        </p>
                    </div>
                    <img src={team} alt='' className='lg:inline-block hidden' />
                </div>
            </div>
        </div>
    );
}

export default Aboutpage;




