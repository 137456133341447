import React, { useState } from 'react';
import '../App.css';
import Compnay_logo from '../images/logo.png';
import Popup from '../components/popup';
import { FaFacebook, FaLinkedin, FaGithub, FaInstagram } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
    const [showpopup, setshowpopup] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const location = useLocation();

    return (
        <div className='overflow-x-hidden'>
            <header>
                {/* Top Bar with Social Links */}
                <div className='bg-[#367ba0] h-14 rounded-ee-3xl relative left-[-2%] w-screen flex justify-center items-center text-white'>
                    <div className='lg:flex hidden justify-between w-4/5'>
                        <h1>Welcome to WorknConnect Innovators & Services Company</h1>
                        <div className='flex gap-4 text-xl'>Follow us:
                            <div className='flex my-auto gap-3'>
                                <a href='https://www.facebook.com/profile.php?id=61563614937620&mibextid=LQQJ4d' target='_blank' rel='noopener noreferrer'>
                                    <FaFacebook className='hover:scale-110 cursor-pointer' />
                                </a>
                                <a href='https://www.linkedin.com/in/worknconnect-innovators-6b009a321/' target='_blank' rel='noopener noreferrer'>
                                    <FaLinkedin className='hover:scale-110 cursor-pointer' />
                                </a>
                                <a href='https://github.com/WorknConnect-Innovators' target='_blank' rel='noopener noreferrer'>
                                    <FaGithub className='hover:scale-110 cursor-pointer' />
                                </a>
                                <a href='https://www.instagram.com/worknconnectinnovators?igsh=MWY2ODIxMmpoMTZrcQ==' target='_blank' rel='noopener noreferrer'>
                                    <FaInstagram className='hover:scale-110 cursor-pointer' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main Navbar */}
                <div className='flex h-20 w-full'>
                    <div className='lg:w-[3.5%] sm:w-0 bg-[#367ba0]'></div>
                    <div className='lg:w-[96.5%] w-full bg-white relative lg:rounded-ss-3xl md:rounded-ss-2xl sm:rounded-ss-2xl z-10 left-[-2%] flex justify-center items-center'>
                        <nav className='flex lg:w-11/12 w-full lg:px-0 px-4 justify-between'>
                            {/* Logo Section */}
                            <div className='flex'>
                                <img src={Compnay_logo} alt='logo' className='h-16' />
                                <div className='my-auto ml-2'>
                                    <p className='text-sm font-medium'>WorknConnect <span className='text-gray-500'>Innovators</span></p>
                                    <p className='text-xs font-normal text-gray-500'>Software Company</p>
                                </div>
                            </div>

                            {/* Desktop Navigation */}
                            <div className='hidden lg:flex my-auto'>
                                <ul className='flex gap-x-8 text-sm lg:text-lg cursor-pointer'>
                                    {['home', 'about', 'products', 'services', 'team', 'contact'].map((item) => (
                                        <li
                                            key={item}
                                            className={`hover:text-[#367ba0] font-semibold text-xl relative transition-all duration-300
                                                ${location.pathname === `/${item === 'home' ? '' : item}`
                                                ? "text-[#367ba0] after:absolute after:content-[''] after:block after:w-full after:h-1 after:rounded-full after:bg-[#367ba0] after:bottom-[-5px] after:left-0 after:transition-all after:duration-300"
                                                : ""}`}
                                        >
                                            <Link to={`/${item === 'home' ? '' : item}`}>
                                                {item.charAt(0).toUpperCase() + item.slice(1)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Register Button (Desktop Only) */}
                            <div className='hidden lg:block my-auto animate-bounce hover:animate-none'>
                                <Link to={'/survey'}  className='px-4 py-2 text-center text-white text-sm lg:text-lg font-medium uppercase rounded-lg bg-[#367ba0]' id='survey'>
                                    Contribute with us
                                </Link>
                            </div>

                            {/* Mobile Menu Toggle Button */}
                            <div className='lg:hidden flex items-center'>
                                <button
                                    aria-label="Toggle navigation"
                                    className='text-gray-800 focus:outline-none'
                                    onClick={() => setShowSidebar(!showSidebar)}
                                >
                                    <svg className='w-6 h-6' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h16'></path>
                                    </svg>
                                </button>
                            </div>
                        </nav>
                    </div>
                </div>

                {/* Sidebar Navigation for Mobile */}
                <div className={`fixed top-0 left-0 h-full w-3/4 max-w-xs bg-white shadow-lg z-50 transform transition-transform ${showSidebar ? 'translate-x-0' : '-translate-x-full'}`}>
                    <button
                        aria-label="Close navigation"
                        className='text-gray-800 p-4 focus:outline-none'
                        onClick={() => setShowSidebar(false)}
                    >
                        <svg className='w-6 h-6' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                        </svg>
                    </button>
                    <ul className='flex flex-col items-start px-6 gap-y-2 text-lg cursor-pointer py-4'>
                        {['home', 'about', 'products', 'services', 'team', 'contact'].map((item) => (
                            <li key={item} onClick={() => setShowSidebar(false)}>
                                <Link to={`/${item === 'home' ? '' : item}`} className='block w-full py-2 hover:text-[#367ba0]'>
                                    {item.charAt(0).toUpperCase() + item.slice(1)}
                                </Link>
                            </li>
                        ))}
                        <li className='w-full pt-4'>
                            <Link to={'/survey'} className='w-full block px-4 py-2 text-center text-white text-lg font-medium uppercase rounded-lg bg-[#367ba0]'>
                                Contribute with us
                            </Link>
                        </li>
                    </ul>
                </div>
            </header>

            {showpopup && <Popup onclose={() => setshowpopup(false)} />}
        </div>
    );
}

export default Navbar;
