import React from 'react';
import { RxCrossCircled } from "react-icons/rx";

function Popup({ onclose }) {
    return (
        <div className='h-screen w-screen z-10 flex justify-center items-center fixed inset-0 bg-white bg-opacity-20 backdrop-blur-sm'>
            <div className='flex flex-col'>
                <RxCrossCircled className='place-self-end mb-3 cursor-pointer text-gray-800 hover:text-red-500 transition-colors duration-200' size={30} onClick={onclose} />
                <div className='bg-[#367ba0] w-fit lg:p-20 md:p-20 p-6  rounded-lg shadow-lg'>
                    <h1 className='text-white text-center text-4xl font-bold mb-8'>Book a Service</h1>
                    <form action='https://api.web3forms.com/submit' method='POST' className='flex flex-col gap-y-4'>
                        <input
                            type='hidden'
                            name='access_key'
                            value='5dc4e651-679e-4a24-b2ed-2b64e7049897'
                        />

                        <input
                            type="hidden"
                            name="redirect"
                            value="https://wncinnovators.com/thanks"
                        />

                        <input
                            type="hidden"
                            name="Response From"
                            value="This form is submitted through Hire Now"
                        />

                        <input
                            name='Name'
                            type='text'
                            className="px-4 py-2 rounded-lg lg:w-96 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#367ba0] focus:border-[#367ba0]"
                            required
                            placeholder='Name'
                        />
                        <input
                            name='Email'
                            type='email'
                            className="px-4 py-2 rounded-lg lg:w-96 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#367ba0] focus:border-[#367ba0]"
                            required
                            placeholder='Email'
                        />

                        <select
                            className="px-4 py-2 rounded-lg lg:w-96 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#367ba0] focus:border-[#367ba0]"
                            name='Category'
                            required
                        >
                            <option value='' disabled selected>Select Category</option>
                            <option value='Web Development'>Web Development</option>
                            <option value='Software Development'>Software Development</option>
                            <option value='WordPress Development'>WordPress Development</option>
                            <option value='AI Development'>AI Development</option>
                            <option value='Mobile App Development'>Mobile App Development</option>
                            <option value='Data Science'>Data Science</option>
                            <option value='Data Science'>Web Scraping & Automation</option>
                            <option value='Data Science'>Cyber Security</option>
                        </select>

                        <div className="flex gap-x-2 lg:w-96">
                            <input
                                name='Budget'
                                type='number'
                                min='0'
                                className="px-4 py-2 rounded-lg w-full text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#367ba0] focus:border-[#367ba0]"
                                required
                                placeholder='Budget'
                            />
                            <select
                                name='Currency'
                                className="px-4 py-2 rounded-lg text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#367ba0] focus:border-[#367ba0]"
                                required
                            >
                                <option value='USD'>USD</option>
                                <option value='EUR'>EUR</option>
                                <option value='GBP'>GBP</option>
                                <option value='INR'>INR</option>
                                <option value='PKR'>PKR</option>
                                <option value='AUD'>AUD</option>
                                <option value='CAD'>CAD</option>
                                <option value='JPY'>JPY</option>
                            </select>
                        </div>

                        <button type='submit' className='bg-gray-800 w-fit px-16 py-2 rounded-full text-xl text-white font-semibold hover:bg-gray-700 transition-colors duration-200'>
                            Send
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Popup;
