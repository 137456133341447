import React from 'react'
import Hero from '../components/hero'
import Features from '../components/features'
import Testimonials from '../components/testimonials'
import Stat from '../components/stat'
import PartnersClientsSlider from '../components/client'
import Chooseus from '../components/chooseus'
import Reviews from '../components/reviews'


function Home() {

  document.title = 'Home - WorknConnect Innovators'

  return (
    <div className='overflow-x-hidden' >
      <Hero />
      <Features />
      <Chooseus />
      <Testimonials />
      <Stat />
      <Reviews/>
      <PartnersClientsSlider />
    </div>
  )
}

export default Home
