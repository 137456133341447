import React, { useState, useEffect, useRef } from 'react';
import li1 from '../images/management.png';
import team from '../images/team.avif';
import software from '../images/software.webp';
import quality from '../images/badge.png';
import platform from '../images/innovation.png';

function Chooseus() {
    const [activeClients, setActiveClients] = useState(0);
    const [recentProjects, setRecentProjects] = useState(0);
    const [hasAnimated, setHasAnimated] = useState(false);

    const clientRef = useRef(null);
    const projectRef = useRef(null);

    const animateCountUp = (setter, target) => {
        let currentCount = 0;
        const increment = Math.ceil(target / 100);

        const interval = setInterval(() => {
            currentCount += increment;
            if (currentCount >= target) {
                clearInterval(interval);
                setter(target);
            } else {
                setter(currentCount);
            }
        }, 150);
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !hasAnimated) {
                    animateCountUp(setActiveClients, 2);
                    animateCountUp(setRecentProjects, 20);
                    setHasAnimated(true);
                }
            });
        }, { threshold: 0.1 });
        const currentclientRef = clientRef.current

        if (clientRef.current) observer.observe(clientRef.current);
        if (projectRef.current) observer.observe(projectRef.current);

        return () => {
            if (currentclientRef) observer.unobserve(currentclientRef);
            if (currentclientRef) observer.unobserve(currentclientRef);
        };
    }, [hasAnimated]);

    return (
        <section className='lg:px-20 px-8 lg:pb-20 pb-8'>
            <h1 className='text-[#367ba0] lg:text-4xl text-xl font-bold'>Why Choose WorknConnect Innovators</h1>
            <p className='my-2'>We bring fresh, cutting-edge approaches to industry-academia collaboration.</p>
            <div className='flex lg:flex-row flex-col gap-20 my-20'>
                <div className='flex flex-col gap-y-10 lg:w-1/2 w-full'>
                    <div className='flex gap-3'>
                        <img src={li1} alt='' className='bg-[#367ba0] h-16 p-2 rounded-full' />
                        <div className='my-auto'>
                            <h1 className='text-xl font-bold'>Experienced Team</h1>
                            <p>Our skilled developers bring deep expertise in the latest technologies and methodologies.</p>
                        </div>
                    </div>
                    <div className='flex gap-3'>
                        <img src={quality} alt='' className='bg-[#367ba0] h-16 p-2 rounded-full' />
                        <div className='my-auto'>
                            <h1 className='text-xl font-bold'>Quality Assurance</h1>
                            <p>We ensure top-tier performance and reliability with rigorous testing and quality checks.</p>
                        </div>
                    </div>
                    <div className='flex gap-3'>
                        <img src={platform} alt='' className='bg-[#367ba0] h-16 p-2 rounded-full' />
                        <div className='my-auto'>
                            <h1 className='text-xl font-bold'>Innovative Approach</h1>
                            <p>We leverage the latest tech trends to deliver cutting-edge solutions that drive growth.</p>
                        </div>
                    </div>
                </div>
                <div className='w-1/2 relative lg:inline-block hidden '>
                    <img src={team} alt='' className='h-72 absolute left-40' />
                    <img src={software} alt='' className='h-72 absolute top-20 border-4 border-white' />
                    <div ref={projectRef} className='absolute hover:shadow-lg cursor-pointer top-64 left-56 border-4 border-white bg-white bg-opacity-50 w-fit pr-40 rounded-xl pl-5  backdrop-blur-lg py-5'>
                        <h1 className='bg-[#367ba0] p-2 rounded-full w-fit text-lg text-white'>{recentProjects}+</h1>
                        <h2 className='mt-2 text-xl font-bold'>Recent Projects</h2>
                    </div>
                    <div ref={clientRef} className='absolute hover:shadow-lg cursor-pointer -top-8 left-0 border-4 border-white bg-white bg-opacity-50 w-fit pr-40 rounded-xl pl-5 backdrop-blur-lg py-5'>
                        <h1 className='bg-[#367ba0] p-2 rounded-full w-fit text-lg text-white'>{activeClients}+</h1>
                        <h2 className='mt-2 text-xl font-bold'>Active Clients</h2>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Chooseus;
