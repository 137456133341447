import React, { useEffect, useState } from 'react'
import blog_img from '../images/indAcade.jpg'
import Aos from 'aos'
import 'aos/dist/aos.css'
import PlatformImg1 from '../images/wnc1.png'
import PlatformImg2 from '../images/wnc2.png'
import PlatformImg3 from '../images/wnc3.png'
import abd from '../images/abdDP.PNG'
import ali from '../images/jaan.PNG'
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa6";


function WorknConnectBlog() {

    document.title = 'Blog - WorknConnect Innovators'

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const PlatformImages = [
        PlatformImg1,
        PlatformImg2,
        PlatformImg3
    ]


    return (
        <div className={` overflow-hidden ${loaded ? 'visible' : 'invisible'}`} >
            <div className="h-[40vh] my-10 w-full flex justify-center items-center relative">
                <img src={blog_img} alt="" className="absolute top-0 left-0 w-full h-full object-cover" />
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 flex flex-col justify-center items-center">
                    <div
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        className="lg:text-7xl text-5xl font-bold text-white text-center "
                    >
                        <h1>Our Blogs</h1>
                        <h2 className='text-xl font-light mt-2' >Blogs | WorknConnect</h2>
                    </div>
                </div>
            </div>

            <div className='lg:px-20 px-4 my-10 flex flex-col gap-y-8 text-center ' >
                <h1 className='font-bold text-3xl' data-aos="fade-up" data-aos-delay="300" >Journey Of WorknConnect</h1>
                <p data-aos="fade-up" data-aos-delay="400" >Every great idea starts with a problem that needs solving. For us at WorknConnect, it was the evident gap between the academic world and industry needs. Students, despite their enthusiasm and potential, often lacked real-world opportunities to apply their knowledge, while industries struggled to find fresh talent equipped with the right skills. With this in mind, the vision for WorknConnect was born to create a platform that empowers students, fosters collaboration, and drives innovation.</p>
                <h2 className='font-bold text-3xl' data-aos="fade-up" >The Beginning</h2>
                <p data-aos="fade-up" data-aos-delay="500" >The journey of WorknConnect began with countless brainstorming sessions fueled by the desire to make a difference. Our initial step was simple: identify how we could connect academia and industry in a way that benefits all stakeholders. After rigorous research and feedback from students, professors, and industry experts, we developed a two-pronged approach:</p>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-x-12 lg:gap-y-0 gap-y-8 lg:px-10' data-aos="fade-right" data-aos-delay="700" >
                    <div className='flex gap-x-6 ' >
                        <h1 className='bg-black text-white rounded-full w-fit h-fit px-4 py-3 text-2xl' >01</h1>
                        <div className='text-start flex flex-col gap-y-2 ' >
                            <h2 className='font-semibold text-2xl' >Supervisor-Mediated Collaboration</h2>
                            <p className='text-gray-800' >Supervisors bridge the gap by bidding on industrial projects, mentoring students, and ensuring project quality.</p>
                        </div>
                    </div>
                    <div className='flex gap-x-6 ' >
                        <h1 className='bg-black text-white rounded-full w-fit h-fit px-4 py-3 text-2xl' >02</h1>
                        <div className='text-start flex flex-col gap-y-2 ' >
                            <h2 className='font-semibold text-2xl' >Direct Industry Engagement</h2>
                            <p className='text-gray-800' >Students directly bid on projects, gaining hands-on experience while industries gain fresh, innovative solutions.</p>
                        </div>
                    </div>
                </div>
                <p data-aos="fade-up" data-aos-delay="500" >Building the platform was no small feat. From designing an intuitive user interface to ensuring seamless functionality, our team worked tirelessly to turn WorknConnect into more than just a concept. We chose to focus on tech students specializing in IT, CS, and SE due to the growing demand for these skills in the market. A major milestone was achieving 60% completion of the platform. Every line of code written and every feature added brought us closer to realizing our vision of transforming the academic experience into one that dynamic, relevant, and aligned with industry needs.</p>
                <div className='grid grid-cols-2 gap-8' >
                    {
                        PlatformImages.map((img, index) => (
                            <div key={index} className='cursor-pointer hover:scale-105 transition-all duration-200' data-aos="fade-up" data-aos-delay="500" >
                                <img src={img} alt='images' />
                            </div>
                        ))
                    }
                </div>
                <h2 className='font-bold text-3xl' data-aos="fade-up" >Overcoming Challenges</h2>
                <p data-aos="fade-up" >No journey is without its hurdles. We faced technical challenges in integrating the platform's key functionalities and balancing the interests of students, supervisors, and industries. Yet, these obstacles taught us resilience and pushed us to innovate further.</p>
                <h2 className='font-bold text-3xl' data-aos="fade-up" >Where We Stand Today</h2>
                <p data-aos="fade-up" >With significant progress made, WorknConnect is not just a platform; it’s a community. We aim to foster an ecosystem where students build real-world skills, supervisors expand their academic horizons, and industries solve their problems innovatively.</p>
                <h2 className='font-bold text-3xl' data-aos="fade-up" >Founder's vision</h2>
                <div className='flex flex-col gap-y-10' >
                    <div className='flex lg:flex-row flex-col w-full gap-x-10 lg:mt-4 mt-8' >
                        <div className='lg:w-1/3 my-auto' data-aos="fade-up" data-aos-delay="500" >
                            <div className='bg-[#367ba0] w-full lg:h-64 h-48 p-4 relative rounded-lg shadow-lg group '>
                                <img src={ali} alt='heroImage' className='relative lg:-top-[77px] -top-[80px]' />
                                <div className="hidden w-fit relative lg:-top-64 -top-56 flex-col gap-y-6 group-hover:flex translate-x-4 ">
                                    <a href='https://www.facebook.com/profile.php?id=100036854552588' target='blank' >
                                        <FaFacebook size={25} color='white' className='cursor-pointer hover:scale-110' />
                                    </a>
                                    <a href="https://www.linkedin.com/in/ali-jaan-75ab02253/" target='blank' >
                                        <FaLinkedin size={25} color='white' className='cursor-pointer hover:scale-110' />
                                    </a>
                                    <a href="https://www.instagram.com/ali_jaan_butt/" target='blank' >
                                        <FaInstagram size={25} color='white' className='cursor-pointer hover:scale-110' />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='lg:w-2/3 h-full justify-center items-center bg-gray-200 lg:px-10 py-10 px-4' data-aos="fade-left" data-aos-delay="800">

                            <figure className="max-w-screen-md mx-auto text-center">
                                <svg className="w-10 h-10 mx-auto mb-3 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                                    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                                </svg>
                                <blockquote>
                                    <p className="text-2xl italic font-medium ">"To bridge the gap between academia and industry by creating a platform that empowers students with real-world opportunities, fosters collaboration, and drives innovation for a brighter future."</p>
                                </blockquote>
                                <figcaption className="flex items-center justify-center mt-6 space-x-3 rtl:space-x-reverse">
                                    <div className="flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-500">
                                        <cite className="pe-3 font-medium ">Muhammad Ali Jaan</cite>
                                        <cite className="ps-3 text-sm ">Founder & Director</cite>
                                    </div>
                                </figcaption>
                            </figure>

                        </div>
                    </div>

                    <div className='flex lg:flex-row flex-col w-full gap-x-10 lg:mt-4 mt-20' >
                        <div className='lg:block hidden lg:w-2/3 h-full justify-center items-center bg-gray-200 p-10' data-aos="fade-right" data-aos-delay="1000">
                            <figure className="max-w-screen-md mx-auto text-center">
                                <svg className="w-10 h-10 mx-auto mb-3 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                                    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                                </svg>
                                <blockquote>
                                    <p className="text-2xl italic font-medium ">"To lead WorknConnect as a global benchmark for industry-academia partnerships, enabling meaningful collaboration, skill development, and impactful solutions that shape tomorrow’s leaders and innovators."</p>
                                </blockquote>
                                <figcaption className="flex items-center justify-center mt-6 space-x-3 rtl:space-x-reverse">
                                    <div className="flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-500">
                                        <cite className="pe-3 font-medium ">Syed Ali Abdullah</cite>
                                        <cite className="ps-3 text-sm ">Co-Founder & CEO</cite>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                        <div className='lg:w-1/3 my-auto' data-aos="fade-up" data-aos-delay="800" >
                            <div className='bg-[#367ba0] w-full lg:h-64 h-48 p-4 relative rounded-lg shadow-lg group '>
                                <img src={abd} alt='heroImage' className='relative lg:-top-[77px] -top-[80px]' />
                                <div className="hidden w-fit relative lg:-top-64 -top-56 flex-col gap-y-6 group-hover:flex translate-x-4 ">
                                    <a href='https://www.facebook.com/syed.abdullah.52643821' target='blank' >
                                        <FaFacebook size={25} color='white' className='cursor-pointer hover:scale-110' />
                                    </a>
                                    <a href="https://www.linkedin.com/in/syed-ali-abdullah-shah-660519336/" target='blank' >
                                        <FaLinkedin size={25} color='white' className='cursor-pointer hover:scale-110' />
                                    </a>
                                    <a href="https://instagram.com/syed_abdullah5/" target='blank' >
                                        <FaInstagram size={25} color='white' className='cursor-pointer hover:scale-110' />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='lg:hidden block w-full h-full justify-center items-center bg-gray-200 px-4 py-10' data-aos="fade-up" data-aos-delay="1000">
                            <figure className="max-w-screen-md mx-auto text-center">
                                <svg className="w-10 h-10 mx-auto mb-3 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                                    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                                </svg>
                                <blockquote>
                                    <p className="text-2xl italic font-medium ">"Flowbite is just awesome. It contains tons of predesigned components and pages starting from login screen to complex dashboard. Perfect choice for your next SaaS application."</p>
                                </blockquote>
                                <figcaption className="flex items-center justify-center mt-6 space-x-3 rtl:space-x-reverse">
                                    <div className="flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-500">
                                        <cite className="pe-3 font-medium ">Michael Gough</cite>
                                        <cite className="ps-3 text-sm ">CEO at Google</cite>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    </div>

                </div>
                <h2 className='font-bold text-3xl' data-aos="fade-up" data-aos-delay="500" >Looking Ahead</h2>
                <p data-aos="fade-up" data-aos-delay="500" >
                    Our journey is far from over. As we continue to refine and expand WorknConnect, our goal remains steadfast: to become the go-to platform for bridging the gap between academia and industry, ensuring that every student has the opportunity to shine and every industry has access to top talent.
                </p>
                <h2 className='font-bold text-3xl' data-aos="fade-up" data-aos-delay="500" >Conclusion</h2>
                <p data-aos="fade-up" data-aos-delay="500" >
                    The story of WorknConnect is one of passion, persistence, and purpose. It’s a journey driven by the belief that collaboration is the key to progress. Together, let’s reimagine the future of education and industry, one project at a time.
                </p>
                <div data-aos="flip-down" data-aos-delay="500" className='bg-gradient-to-r hover:bg-gradient-to-t  from-[#506869] to-[#70a3ae] w-full lg:h-60 h-80 rounded-lg flex justify-center items-center flex-col gap-y-4 ' >
                    <h2 className='text-center text-white font-semibold text-lg border w-fit h-fit px-10 py-2 cursor-pointer hover:bg-white hover:border-black hover:text-black transition-all duration-150 ' >#JoinUs</h2>
                    <p className='w-2/3 text-white' >Are you ready to be part of this revolution? Whether you’re a student, supervisor, or industry professional, WorknConnect welcomes you to join hands in shaping the future.</p>
                </div>
            </div>

        </div>
    )
}

export default WorknConnectBlog