import React from 'react';


function Reviews() {

    return (
        <div className="bg-gray-100 lg:p-20 p-8 w-full flex lg:flex-row flex-col lg:gap-20 gap-8">
            <div className="lg:w-2/6 w-full">
                <h1 className="text-lg font-medium text-gray-500">Reviews</h1>
                <p className="text-3xl my-4 font-semibold">
                    Your <span className="text-[#367ba0]">Reviews & Feedback</span> are very valuable to us
                </p>
                <p className='text-sm text-gray-500 text-justify mt-8'>
                    We deeply appreciate your reviews and feedback. They drive us to continuously improve and exceed your expectations. Your insights help us grow and ensure that we deliver exceptional service every time.
                </p>

            </div>
            <form action='https://api.web3forms.com/submit' method='POST' className="lg:w-4/6 w-full lg:px-20  flex flex-col gap-6 ">

                <input
                    type='hidden'
                    name='access_key'
                    value='5dc4e651-679e-4a24-b2ed-2b64e7049897'
                />
                <input
                 type="hidden" 
                 name="redirect" 
                 value="https://wncinnovators.com/thanks" 
                 />
                <div className="flex lg:flex-row flex-col gap-4">
                    <div className="relative w-full min-w-[200px] h-10">
                        <input
                            className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline-none focus:outline-none disabled:bg-blue-gray-50 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 border focus:border-2 border-black text-sm px-3 py-2.5 rounded-[7px] focus:border-[#367ba0]"
                            placeholder=" "
                            required
                            name="Name"
                        />
                        <label
                            className="absolute left-3 -top-2 text-[11px] bg-gray-100 px-1 peer-placeholder-shown:top-[8px] peer-placeholder-shown:text-sm peer-placeholder-shown:bg-transparent peer-placeholder-shown:px-0 peer-placeholder-shown:text-blue-gray-500 transition-all duration-200 ease-in-out peer-focus:-top-2 peer-focus:text-[11px] peer-focus:bg-gray-100 peer-focus:px-1 peer-focus:text-gray-900 text-gray-500 "
                            htmlFor="Name"
                        >
                            Name
                        </label>
                    </div>


                    <div className="relative w-full min-w-[200px] h-10">
                        <input
                            className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline-none focus:outline-none disabled:bg-blue-gray-50 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 border focus:border-2 border-black text-sm px-3 py-2.5 rounded-[7px] focus:border-[#367ba0]"
                            placeholder=" "
                            name="Designation"
                        />
                        <label
                            className="absolute left-3 -top-2 text-[11px] bg-gray-100 px-1 peer-placeholder-shown:top-[8px] peer-placeholder-shown:text-sm peer-placeholder-shown:bg-transparent peer-placeholder-shown:px-0 peer-placeholder-shown:text-blue-gray-500 transition-all duration-200 ease-in-out peer-focus:-top-2 peer-focus:text-[11px] peer-focus:bg-gray-100 peer-focus:px-1 peer-focus:text-gray-900 text-gray-500 "
                            htmlFor="Designation"
                        >
                            Designation
                        </label>
                    </div>
                </div>
                <div className="relative w-full min-w-[200px] h-48 ">
                    <textarea
                        className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline-none focus:outline-none disabled:bg-blue-gray-50 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 border focus:border-2 border-black text-sm px-3 py-2.5 rounded-[7px] focus:border-[#367ba0]"
                        placeholder=" "
                        name="Feedback"
                    ></textarea>
                    <label
                        className="absolute left-3 -top-2 text-[11px] bg-gray-100 px-1 peer-placeholder-shown:top-[8px] peer-placeholder-shown:text-sm peer-placeholder-shown:bg-transparent peer-placeholder-shown:px-0 peer-placeholder-shown:text-blue-gray-500 transition-all duration-200 ease-in-out peer-focus:-top-2 peer-focus:text-[11px] peer-focus:bg-gray-100 peer-focus:px-1 peer-focus:text-gray-900 text-gray-500 "
                        htmlFor="Feedback"
                    >
                        Feedback
                    </label>
                </div>
                <button className='text-lg px-12 py-1 border border-[#367ba0] bg-[#367ba0] text-white hover:shadow-lg rounded-lg lg:w-fit'  >Send Message</button>
            </form>
        </div>
    );
}

export default Reviews;
