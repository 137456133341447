import React from 'react'
import logo from '../images/logo.png'
import { GrFormNextLink } from "react-icons/gr";


function Thank() {
    return (
        <div class="min-h-screen flex gap-y-10 justify-center flex-col items-center bg-gradient-to-t from-[#367ba0] to-gray-600 ">
            <img src={logo} alt='' className='h-48 rounded-full p-5 ' />
            <p className='w-1/2 text-center text-white  '>
                <strong className='text-lg font-bold '>Thank you!</strong> Your response has been submitted successfully. We will get back to you soon.
                Stay tuned with us for updates and exciting news. We appreciate your interest and look forward to the opportunity to serve you.
            </p>
            <button className='flex gap-2 uppercase border w-fit px-12 py-1 rounded-lg bg-white relative group'>
                <a href='https://wncinnovators.com' >Home</a> 
                <GrFormNextLink size={20} className=' absolute right-6 top-1.5 my-auto opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all duration-300 ease-in-out' />
            </button>
        </div>
    )
}

export default Thank