import React from 'react';
import NASTPLogo from '../images/NastpLogo.png'
import JaffriLogo from '../images/jaffri_Logo.png'
import kochLogo from '../images/KOCH.png'
import NimtrixLogo from '../images/nim.png'
import Marquee from 'react-fast-marquee';
import RP9 from '../images/Regional_Plan9_Logo_1.png'
import PITB from '../images/PITB.jpeg'


const PartnersClientsSlider = () => {

  const logos = [
    NASTPLogo,
    NimtrixLogo,
    JaffriLogo,
    kochLogo,
    RP9,
    PITB,
  ];

  return (
    <div className=' px-20 py-4 my-20 '>
      <h1 className="text-lg font-medium text-gray-500 my-4">Proudly Collaborated With</h1>
      <div className="overflow-hidden flex items-center justify-center">
        <Marquee velocity={50} direction="y" resetAfterTries={200} scatterRandomly={false}>
          {logos.map((logo, index) => (
            <div key={index} className="flex items-center justify-center my-4">
              <img src={logo} alt={`Partner ${index}`} className="h-36 mx-8" />
            </div>
          ))}
        </Marquee>
      </div>
    </div>


  );
};

export default PartnersClientsSlider;
