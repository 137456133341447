import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { IoLogoWhatsapp } from "react-icons/io5";
import Navbar from './components/nav';
import Footer from './components/footer';
import Home from './pages/home';
import Aboutpage from './pages/aboutpage';
import Servicespage from './pages/servicespage';
import TeamsPage from './pages/teamsPage';
import Contact from './pages/contact';
import Thank from './components/thanks';
import Products from './pages/products';
import WorknConnectBlog from './pages/wncBlog';
import PlatformSurvey from './pages/survey';
import { CiSaveUp2 } from "react-icons/ci";

function App() {

  const [showscroll, setshowscroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 20) {
        setshowscroll(true);
      } else {
        setshowscroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <Router className='overflow-x-hidden'>
      <Routes>
        {/* Routes with Navbar and Footer */}
        <Route path="/" element={<PageWithNavbarFooter />}>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<Aboutpage />} />
          <Route path="services" element={<Servicespage />} />
          <Route path='products' element={<Products />} />
          <Route path="team" element={<TeamsPage />} />
          <Route path="contact" element={<Contact />} />
          <Route path='Blogs' element={<WorknConnectBlog />} />
          <Route path='survey' element={<PlatformSurvey />} />
        </Route>

        {/* Route without Navbar and Footer */}
        <Route path="thanks" element={<Thank />} />
      </Routes>

      {/* Top Scroll button  */}
      <div 
        className={`w-fit p-3 rounded-full bg-gray-400 fixed right-5 bottom-24 cursor-pointer ${showscroll ? 'inline-block transition-all duration-200 ' : 'hidden'}`} 
        onClick={() => window.scrollTo(0, 0)}
      >
        <CiSaveUp2 size={35} color='white' />
      </div>

      {/* WhatsApp Button */}
      <div className='w-fit p-3 rounded-full bg-[#367ba0] fixed right-5 bottom-5'>
        <a href='https://api.whatsapp.com/send?phone=923201540500&text=Hello,%20How%20are%20you%3F'>
          <IoLogoWhatsapp size={35} color='white' />
        </a>
      </div>
    </Router>
  );
}

function PageWithNavbarFooter() {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
