import React from 'react';
import '../App.css';
import { FaMapLocationDot, FaPhone, FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';

function Contact() {
  
  document.title = 'Contact - WorknConnect Innovators';

  return (
    <div className="font-[sans-serif]  my-10">
      <div className="bg-gradient-to-r from-[#7196aa] to-[#497389] w-full h-60 ">
        <div className='lg:w-1/2 flex justify-center items-center h-full' >
          <div className='text-white flex flex-col gap-4 w-3/4' >
            <h1 className='font-bold text-3xl' >Get In Touch !</h1>
            <p>Feel free to contact us! submit your queries here and we will get back to you as soon as possible.</p>
          </div>
        </div>
      </div>
      <div className='flex lg:flex-row flex-col-reverse lg:my-0 my-8 ' >
        <div className="mb-6 px-4 lg:w-1/2 flex justify-center items-center">
          <div className='flex flex-col gap-2 lg:w-3/5 ' >
            <p className='flex gap-4 text-xl ' ><FaPhone className='my-auto' /> 0320-1540500 </p>
            <p className='flex gap-4 text-xl ' ><MdEmail className='my-auto' /> info@wncinnovators.com </p>
            <p className='flex gap-4 text-xl ' ><FaMapLocationDot className='my-auto' /> Gujrat, Pakistan </p>
            <hr className='border  mt-4 ' />
            <div className='flex my-auto gap-2 text-2xl mt-2'>
              <a href='https://www.facebook.com/profile.php?id=61563614937620&mibextid=LQQJ4d' target='blank'>
                <FaFacebook className='hover:scale-110 cursor-pointer hover:text-[#367ba0] ' />
              </a>
              <a href='https://www.linkedin.com/in/worknconnect-innovators-6b009a321/' target='blank'>
                <FaLinkedin className='hover:scale-110 cursor-pointer hover:text-[#367ba0] ' />
              </a>
              <a href='https://www.instagram.com/worknconnectinnovators?igsh=MWY2ODIxMmpoMTZrcQ==' target='blank'>
                <FaInstagram className='hover:scale-110 cursor-pointer hover:text-[#367ba0] ' />
              </a>
              <a href='https://www.instagram.com/worknconnectinnovators?igsh=MWY2ODIxMmpoMTZrcQ==' target='blank'>
                <FaTwitter className='hover:scale-110 cursor-pointer hover:text-[#367ba0] ' />
              </a>
            </div>
          </div>

        </div>
        <div className="lg:-mt-36 mb-6 px-4 lg:w-1/2">
          <div className="mx-auto max-w-6xl lg:shadow-lg border-2 p-8 relative bg-white rounded-lg">
            <h2 className="text-xl text-gray-800 font-bold">Feel free to reach us !</h2>

            <form action='https://api.web3forms.com/submit' method='POST' className="mt-8 flex flex-col gap-4 ">

              <input
                type='hidden'
                name='access_key'
                value='5dc4e651-679e-4a24-b2ed-2b64e7049897'
              />

              <input
                type="hidden"
                name="redirect"
                value="https://wncinnovators.com/thanks"
              />

              <input
                type="hidden"
                name="Response From"
                value="This form is submitted through contact us"
              />

              <div className='grid sm:grid-cols-2 gap-6'>
                <div>
                  <input
                    name='Name'
                    type='text'
                    placeholder='Name'
                    className="w-full rounded-lg py-2.5 px-4 border border-gray-300 text-sm outline-[#367ba0]" />
                </div>
                <div>
                  <input
                    type='email'
                    placeholder='Email'
                    name='Email'
                    className="w-full rounded-lg py-2.5 px-4 border border-gray-300 text-sm outline-[#367ba0]" />
                </div>
              </div>
              <div>
                <textarea
                  placeholder='Message'
                  rows="6"
                  name='Message'
                  className="col-span-full w-full rounded-lg px-4 border border-gray-300 text-sm pt-3 outline-[#367ba0]"></textarea>
              </div>


              <button
                    type="submit"
                    className="relative group text-white w-max bg-[#3e8ab3] hover:bg-[#367ba0] rounded-lg text-sm px-6 py-3 mt-4 tracking-wide overflow-hidden"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                      fill="#fff"
                      className="absolute left-4 top-1/2 -translate-y-1/2 transition-all duration-300 group-hover:left-1/2 group-hover:-translate-x-1/2"
                      viewBox="0 0 548.244 548.244"
                    >
                      <path
                        fillRule="evenodd"
                        d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.077-13.923 9.078-26.24 22.338-30.498L506.15 1.549c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666L390.89 525.906c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="group-hover:opacity-0 group-hover:scale-75 mx-4 transition-all duration-300">
                      Send Message
                    </span>
                  </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
