import React, { useState, useEffect } from 'react'
import HeroImage from '../images/HeroImage.svg'
import Popup from './popup'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom'


function Hero() {

    useEffect(() => {
        Aos.init({duration: 2000})
    },[])

    const [showpopup, setshowpopup] = useState(false)
    return (
        <div className='bg-gray-100 h-[82vh] lg:px-20 px-6  w-screen flex lg:flex-row flex-col-reverse lg:justify-between justify-center items-center'>
            <div className='lg:w-1/2 w-full' data-aos = 'fade-right' >
                <div className='lg:w-4/5 w-full '>
                    <p className='lg:text-4xl lg:inline-block hidden text-xl my-4'>Achieve your business goals with a leading <strong className='text-[#367ba0]'>software development agency</strong></p>
                    <p>Grow your business, save time, and create beautiful custom software that delivers outstanding results</p>
                    <div className='flex gap-2' >
                        <button onClick={() => setshowpopup(true)} className='px-6 py-1 text-center mt-8 text-white lg:text-xl md:text-xl text-lg font-medium uppercase rounded-lg bg-[#367ba0] hover:shadow'>
                            Hire Now
                        </button>
                        <button className='px-6 py-1 lg:hidden animate-bounce text-center mt-8 text-white lg:text-xl md:text-xl text-lg font-medium uppercase rounded-lg bg-[#367ba0] hover:shadow'>
                           <Link to={'/survey'} >Contribute</Link>
                        </button>
                    </div>
                </div>
            </div>
            <div className='lg:w-1/2 w-full' data-aos = 'fade-left'>
            <p className='lg:hidden text-2xl my-4'>Achieve your business goals with a leading <strong className='text-[#367ba0]'>software development agency</strong></p>
                <img src={HeroImage} alt='heroImage' />
            </div>
            {
                showpopup && <Popup
                    onclose={() => setshowpopup(false)}
                />
            }
        </div>
    )
}

export default Hero
