import React, { useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import profile_image1 from '../images/avator.webp'
function Testimonials() {
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: false,
        prevArrow: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const testimonials = [
        {
            name: "Syed Momin",
            title: "Project Manager, NASTP",
            feedback: "WorknConnect Innovators delivered a scalable solution that transformed our operations. Their expertise and timely delivery exceeded expectations.",
            image: profile_image1
        },
        {
            name: "Jawad Ahmad",
            title: "Software Engineer",
            feedback: "WorknConnect Innovators turned our complex challenge into a reliable, innovative solution. Their creativity and expertise delivered beyond expectations, driving measurable value.",
            image: "https://pagedone.io/asset/uploads/1696229969.png"
        },
        {
            name: "Sophia W.",
            title: "HR Director",
            feedback: "The platform's interface is so user-friendly, and it’s helped us connect with students efficiently. We’ve onboarded some amazing interns!",
            image: "https://pagedone.io/asset/uploads/1696229969.png"
        },
    ];

    return (
        <div className="bg-gray-100 lg:p-20 p-8 w-full flex lg:flex-row flex-col lg:gap-20 gap-8">
            <div className="lg:w-2/6 w-full">
                <h1 className="text-lg font-medium text-gray-500">Testimonials</h1>
                <p className="text-3xl my-4 font-semibold">
                    Our <span className="text-[#367ba0]">Customers</span> Love Us
                </p>
                <p className='text-sm text-gray-500 text-justify mt-8'>
                    Reading these heartfelt testimonials is truly rewarding. Their positive feedback highlights our team's passion and commitment. We’re grateful for your trust and look forward to delivering even more outstanding results.
                </p>
            </div>
            <div className="lg:w-4/6 w-full">
                <Slider ref={sliderRef} {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index}>
                            <div className="mr-2 bg-white border border-solid border-gray-300 rounded-2xl p-6 transition-all duration-500 hover:border-[#367ba0] cursor-pointer">
                                <div className="flex items-center gap-5 mb-5 sm:mb-9">
                                    <img className="rounded-full h-14" src={testimonial.image} alt="avatar" />
                                    <div className="grid gap-1">
                                        <h5 className="text-gray-900 font-medium">{testimonial.name}</h5>
                                        <span className="text-sm leading-6 text-gray-500">{testimonial.title}</span>
                                    </div>
                                </div>
                                <div className="flex items-center mb-5 gap-2 text-amber-500">
                                    {[...Array(5)].map((_, i) => (
                                        <svg key={i} className="w-5 h-5" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    ))}
                                </div>
                                <p className="text-sm text-gray-500 leading-6 transition-all duration-500 min-h-24 group-hover:text-gray-800">
                                    {testimonial.feedback}
                                </p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Testimonials;
