import React, { useState } from "react";

function PlatformSurvey() {

    document.title = 'Survey - WorknConnect Innovators'

    const [usertype, setusertype] = useState('')

    return (
        <div className="lg:p-12 p-4 bg-gray-100">
            <div
                className="bg-white lg:p-10 p-6 mb-4 rounded-lg shadow-lg"
            >
                Our platform bridges the gap between academia and industries by enabling students, supervisors, and companies to collaborate on real-world projects. By fostering skill development and industry connections, we aim to create a seamless pathway for students to enter the workforce while helping industries find top talent.Your feedback is essential in shaping this vision into reality! Please take a few minutes to fill out this survey and help us understand how we can best meet your needs.
            </div>
            <form
                className="bg-white lg:p-10 p-6 rounded-lg shadow-lg"
            >
                <h1 className="text-2xl font-semibold text-gray-800 mb-6">
                    Platform Survey
                </h1>

                {/* User Type Selection */}
                <div className="mb-6">
                    <h2 className="text-lg font-semibold text-gray-800 mb-4">
                        Select Your Role
                    </h2>
                    <div className="flex flex-col gap-y-2 gap-x-4 lg:flex-row">
                        {["Industry", "Student", "Supervisor"].map((type, index) => (
                            <div
                                key={index}
                                className="relative flex lg:w-56 w-full items-center justify-center rounded-xl bg-gray-50 px-4 py-3 font-medium text-gray-700"
                            >
                                <input
                                    className="peer hidden"
                                    type="radio"
                                    name="usertype"
                                    id={`radio${index}`}
                                    value={type}
                                    onChange={(e) => setusertype(e.target.value)}
                                />
                                <label
                                    htmlFor={`radio${index}`}
                                    className="peer-checked:border-[#367ba0] peer-checked:bg-[#367ba0] absolute top-0 h-full w-full cursor-pointer rounded-xl border"
                                ></label>
                                <span className="pointer-events-none peer-checked:text-white z-10">{type}</span>
                            </div>
                        ))}
                    </div>
                    <div className="mt-6 text-center">
                        {
                            usertype === "Industry" ? (
                                <a
                                    href="https://forms.gle/G9wAJWo8cHaaNJnM6"
                                    target='blank'
                                    className="hover:bg-[#367ba0] hover:text-white px-20 py-3 border text-black border-[#367ba0] rounded-lg shadow transition"
                                >
                                    Start Survey
                                </a>
                            ) :
                                usertype === "Student" ? (
                                    <a
                                        href="https://forms.gle/fTbbgabyeNjKiZ9e6"
                                        target='blank'
                                        className="hover:bg-[#367ba0] hover:text-white px-20 py-3 border text-black border-[#367ba0] rounded-lg shadow transition"
                                    >
                                        Start Survey
                                    </a>
                                ) :
                                    usertype === "Supervisor" ? (
                                        <a
                                            href="https://forms.gle/LEP2xfExC5qNAGUJA"
                                            target='blank'
                                            className="hover:bg-[#367ba0] hover:text-white px-20 py-3 border text-black border-[#367ba0] rounded-lg shadow transition"
                                        >
                                            Start Survey
                                        </a>
                                    ) : (
                                        <div></div>
                                    )
                        }

                    </div>
                </div>


            </form>
        </div>
    );
}

export default PlatformSurvey;
