import React from 'react'
import company_logo from '../images/company_logo.png'
import { BsTelephoneFill } from "react-icons/bs";
import { MdMarkEmailRead } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";


function Footer() {
  return (
    <div className='bg-gray-200 lg:px-20 px-8 py-10 ' >
        <img src={company_logo} alt='' className='lg:h-28 h-20 ' />
      <div className='grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 lg:gap-10 gap-4 '>
        <p className='text-sm text-gray-500 text-justify '>Worknconnect Innovators is a pioneering company focused on transforming software development. We bridge industry and academia, driving innovation through collaboration. Our mission is to deliver cutting-edge solutions that blend industry demands with academic insights, keeping our clients at the forefront of technology.</p>
        <div className='lg:mx-auto'>
            <h1 className='text-xl font-bold text-[#367ba0] lg:mb-4' >Our Company</h1>
            <ul className='flex flex-col gap-y-2' >
                <li>Home</li>
                <li>About Us</li>
                <li>Contact us</li>
            </ul>
        </div>
        <div className='lg:mx-auto'>
            <h1 className='text-xl font-bold text-[#367ba0] lg:mb-4'>Our Products</h1>
            <ul className='flex flex-col gap-y-2'>
                <li>WorknConnect</li>
            </ul>
        </div>
        <div className='lg:mx-auto'>
            <h1 className='text-xl font-bold text-[#367ba0] lg:mb-4 '>Popular Services</h1>
            <ul className='flex flex-col gap-y-2'>
                <li>Web Development</li>
                <li>AI Development</li>
                <li>Deep Learning</li>
                <li>Cyber Security</li>
            </ul>
        </div>
        <div className='lg:mx-auto'>
            <h1 className='text-xl font-bold text-[#367ba0] lg:mb-4 '>Contact</h1>
            <ul className='flex flex-col gap-y-2'>
                <li className='flex gap-2' ><BsTelephoneFill color='#367ba0' size={20} className='my-auto' /> +92 320 1540500 </li>
                <li className='flex gap-2' ><MdMarkEmailRead color='#367ba0' size={20} className='my-auto'  />info@wncinnovators.com</li>
                <li className='flex gap-2' ><FaLocationDot color='#367ba0' size={20} className='my-auto' />Gujrat, Pakistan</li>
            </ul>
        </div>
      </div>
      <hr className='border border-gray-400 my-16' />
      <div className='flex justify-center text-center'>
        <p>© 2024 Worknconnect Innovators. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer
