import React, { useState, useEffect, useRef } from 'react';

function Stat() {
    const [developercount, setdevelopercount] = useState(0);
    const [repoCount, setrepoCount] = useState(0);
    const [projectsCount, setprojectsCount] = useState(0);
    const [contributersCount, setcontributersCount] = useState(0);
    // const [companyCount, setcompanyCount] = useState(0);
    // const [orjCount, setorjCount] = useState(0);

    const statRef = useRef(null);
    const [hasAnimated, setHasAnimated] = useState(false);

    const animateCountUp = (setter, target) => {
        let currentCount = 0;
        const increment = Math.ceil(target / 100);

        const interval = setInterval(() => {
            currentCount += increment;
            if (currentCount >= target) {
                clearInterval(interval);
                setter(target);
            } else {
                setter(currentCount);
            }
        }, 30);
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting && !hasAnimated) {
                animateCountUp(setdevelopercount, 3);
                animateCountUp(setrepoCount, 20);
                animateCountUp(setprojectsCount, 30);
                animateCountUp(setcontributersCount, 3);
                // animateCountUp(setcompanyCount, 5);
                // animateCountUp(setorjCount, 3);
                setHasAnimated(true);
            }
        }, {
            threshold: 0.1,
        });
        const currentStatRef = statRef.current

        if (statRef.current) {
            observer.observe(statRef.current);
        }

        return () => {
            if (currentStatRef) {
                observer.unobserve(currentStatRef);
            }
        };
    }, [hasAnimated]);

    return (
        <div ref={statRef} className="mx-10 bg-[#367ba0] rounded-lg md:p-8 my-20">
            <dl className="grid lg:grid-cols-4 gap-8 p-4 mx-auto text-white sm:grid-cols-2 xl:grid-cols-4 sm:p-8">
                <div className="flex flex-col items-center justify-center">
                    <dt className="mb-2 text-3xl font-extrabold">{developercount}+</dt>
                    <dd className="text-gray-300">Developers</dd>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <dt className="mb-2 text-3xl font-extrabold">{repoCount}+</dt>
                    <dd className="text-gray-300">Public repositories</dd>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <dt className="mb-2 text-3xl font-extrabold">{projectsCount}+</dt>
                    <dd className="text-gray-300">Open source projects</dd>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <dt className="mb-2 text-3xl font-extrabold">{contributersCount}+</dt>
                    <dd className="text-gray-300">Contributors</dd>
                </div>
                {/* <div className="flex flex-col items-center justify-center">
                    <dt className="mb-2 text-3xl font-extrabold">{companyCount}+</dt>
                    <dd className="text-gray-300">Top Forbes companies</dd>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <dt className="mb-2 text-3xl font-extrabold">{orjCount}+</dt>
                    <dd className="text-gray-300">Organizations</dd>
                </div> */}
            </dl>
        </div>
    );
}

export default Stat;
