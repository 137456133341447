import React from 'react'
import speed from '../images/fea1.png'
import uiux from '../images/fea2.png'
import integration from '../images/fea3.svg'


function Features() {
    return (
        <section className=' w-full  flex flex-col lg:py-28 py-10'>
            <div className='lg:flex lg:px-20 px-8 '>
                <h1 className='lg:text-6xl text-4xl font-semibold lg:w-1/2 w-full'>
                    Core features all <br /> <strong className='text-[#367ba0] font-semibold' >softwares</strong> need
                </h1>
                <p className='lg:w-1/2 text-gray-600'>Outstanding and powerful features that make WorknConnect Innovators a best company for our clients include seamless integration with existing workflows, advanced AI-driven insights for smarter decision-making, and an intuitive design that enhances user productivity and satisfaction.</p>
            </div>
            <div className='w-full flex lg:flex-row flex-col gap-16 lg:p-20 p-8' >
                <div className='text-center text-white lg:w-1/3 w-full  p-14 bg-gradient-to-r hover:bg-gradient-to-t  from-[#506869] to-[#70a3ae] rounded-lg '>
                    <img src={speed} alt='' className='mx-auto' />
                    <h1 className='mt-8 text-2xl mb-3 font-bold' >Top notch performance</h1>
                    <p>Build a software that loads fast for the highest user experience.</p>
                </div>
                <div className='text-center text-white lg:w-1/3 w-full p-14 bg-gradient-to-r hover:bg-gradient-to-t from-[#989fa7] to-[#53686c] rounded-lg '>
                    <img src={uiux} alt='' className='mx-auto' />
                    <h1 className='mt-8 text-2xl mb-3 font-bold' >User-Friendly Interface</h1>
                    <p>Streamlines navigation, improves user engagement.</p>
                </div>
                <div className='text-center text-white lg:w-1/3 w-full p-14 bg-gradient-to-r hover:bg-gradient-to-t from-[#506869] to-[#70a3ae] rounded-lg '>
                    <img src={integration} alt=''className='mx-auto mt-10' />
                    <h1 className='mt-8 text-2xl mb-3 font-bold' >Cross-Platform Compatibility</h1>
                    <p>Works seamlessly across different devices and operating systems.</p>
                </div>
            </div>
        </section>
    )
}

export default Features
